import React from "react"
import loadable from "@loadable/component";
import styled from "styled-components";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Button, HeaderBG, SassyText, Section, SectionWrapper, SubTitle, Text, Title } from "../components/core";
import { useMixpanel } from "../context/MixpanelContext";
import { MetaComponent } from "../components";
import { OnDesktop, OnMobile } from "../components/DesktopMobileComps";
import useHindiLangFont from "../hooks/useHindiLangFont";
import { isBrowser } from "../utils/browserUtils";
import { LeadFormProvider } from "../context/LeadFormContext";

const Footer = loadable(() => import("../components"), {
    resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../components/LeadForm"));
const Navbar = loadable(() => import("../components"), {
    resolveComponent: (components) => components.Navbar,
});

const TeamMember = ({dataKey, imgData}) => {
    const {t} = useTranslation(['our-team'])
    const { hindiBold, hindiRegular } = useHindiLangFont()
    const image = getImage(imgData)
    return (
        <div style={{width: "240px"}}>
            <TeamMemberImgWrapper>
                <GatsbyImage 
                    image={image}
                    alt={dataKey}
                />
            </TeamMemberImgWrapper>
            <Text fontSize="20px" mfontSize="14px" fontWeight="bold" lineHeight="25.2px" mlineHeight="17.6px" style={{marginTop: "12px"}} className={hindiBold}>
                {t(`TeamMembersSection.${dataKey}.Name`)}
            </Text>
            <Text fontSize="16px" mfontSize="12px" lineHeight="20px" mlineHeight="16px" color="rgba(141, 141, 141, 1)" style={{marginTop: "12px"}} className={hindiRegular}>
                {t(`TeamMembersSection.${dataKey}.Designation`)}
            </Text>
            <Text fontSize="14px" mfontSize="12px" lineHeight="28px" color="rgba(76, 76, 76, 1)" style={{marginTop: "16px"}} className={hindiRegular}>
                {t(`TeamMembersSection.${dataKey}.Desc`)}
            </Text>
        </div>
    )
}

const OpenRoleCard = ({openRoleData}) => {
    const {t} = useTranslation('our-team')
    const { hindiBold, hindiRegular } = useHindiLangFont()

    const copyEmailToClipboard = () => {
        const email = openRoleData.Email || ""
        if (isBrowser && email) {
            navigator.clipboard.writeText(email).then(() =>  alert(`Email: ${email} copied to clipboard`))
        }
    }

    return (
        <OpenRoleCardContainer>
            <Text fontWeight="bold" fontSize="20px" mfontSize="18px" className={hindiBold}>
                {openRoleData.JobTitle}
            </Text>
            <OpenRoleDataContainer>
                <OpenRoleData>
                    <OpenRoleDataType className={hindiRegular}>{t('OpenRolesFields.Location')}:</OpenRoleDataType>
                    <OpenRoleDataValue className={hindiRegular}>{openRoleData.Location}</OpenRoleDataValue>
                    <OpenRoleDataType className={hindiRegular}>{t('OpenRolesFields.Qualifications')}:</OpenRoleDataType>
                    <OpenRoleDataValue className={hindiRegular}>{openRoleData.Qualifications}</OpenRoleDataValue>
                    <OpenRoleDataType className={hindiRegular}>{t('OpenRolesFields.Language')}:</OpenRoleDataType>
                    <OpenRoleDataValue className={hindiRegular}>{openRoleData.Language}</OpenRoleDataValue>
                    <OpenRoleDataType className={hindiRegular}>{t('OpenRolesFields.Experience')}:</OpenRoleDataType>
                    <OpenRoleDataValue className={hindiRegular}>{openRoleData.Experience}</OpenRoleDataValue>
                    <OpenRoleDataType className={hindiRegular}>{t('OpenRolesFields.Compensation')}:</OpenRoleDataType>
                    <OpenRoleDataValue className={hindiRegular}>{openRoleData.Compensation}</OpenRoleDataValue>
                </OpenRoleData>
                <OpenRoleApplyBtn label={openRoleData.ApplyAt} uppercaseLabel={false} onClick={copyEmailToClipboard} />
            </OpenRoleDataContainer>
        </OpenRoleCardContainer>
    )
}

const hyperlinkStyles = {
    textDecoration: "none", 
    color: "#000"
}

const OurTeam = ({data}) => {
    const {t} = useTranslation(['our-team', 'common'])
    const { hindiBold, hindiRegular } = useHindiLangFont()
    const mixpanel = useMixpanel()
    const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

    const handleQuoteModalOpen = () => {
        setIsQuoteModalOpen(true);
    };

    const handleQuoteModalClose = () => {
        setIsQuoteModalOpen(false);
    };

    const handleGetQuoteClick = (e, mxEventName = "") => {
        if (mxEventName) {mixpanel.track(mxEventName)}
        handleQuoteModalOpen()
    }

    // find the team member's image based on their name
    const findImageData = (imgName) => {
        return data.team_member_photos?.edges?.find(el => el.node?.relativePath?.includes(imgName))?.node
    }

    return (
        <>
            <LeadFormProvider>
                {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
            </LeadFormProvider>
            <MetaComponent
                title="Our Team - Verak"
                description="Verak's 60+ team is working tirelessly to make sure insurance isn't a source of stress for businesses. Learn more about our team or join our mission to insure 10 Lakh SMEs by 2027."
                url="aboutus"
            />
            <Navbar active="aboutus" />
            
            <Section background="#ffffff" style={{marginTop: "80px"}}>
                <OnDesktop><HeaderBG fullBg imgStyle={{height: "570px"}} /></OnDesktop>
                <OnMobile><HeaderBG fullBg imgStyle={{height: "800px"}} /></OnMobile>
                <SectionWrapper>
                    <Title fontSize="58px" mfontSize="32px" lineHeight="70px" mlineHeight="38px" style={{textAlign: "left"}} className={hindiBold}>
                        <SassyText>{t('Title')}</SassyText>
                    </Title>
                    <OurTeamSubHeader>
                        <TeamGraphicContainer>
                            <StaticImage 
                                src="../assets/images/team_graphic.webp"
                                alt="team"
                                width={334}
                                height={168}
                                loading="lazy"
                            />
                        </TeamGraphicContainer>
                        <Text fontSize="30px" mfontSize="18px" fontWeight="bold" lineHeight="51px" mlineHeight="30.6px" style={{textAlign: "left"}} className={hindiBold}>
                            <Trans i18nKey="Subtitle">Our <SassyText>60+ strong team</SassyText> is spread across <SassyText>17 cities</SassyText> in India and is always ready to help clients in their moments of need.</Trans>
                        </Text>
                    </OurTeamSubHeader>
                </SectionWrapper>
            </Section>
            <TeamMembersSection>
                <SectionWrapper>
                    <SubTitle fontSize="36px" mfontSize="24px" lineHeight="43px" mlineHeight="28.8px" style={{textAlign: "left"}} className={hindiBold}>
                        <SassyText><Trans i18nKey="TeamMembersSection.Title">Here’s why we do what we do</Trans></SassyText>
                    </SubTitle>
                </SectionWrapper>
                <TeamMembersContainer>
                    <TeamMember imgData={findImageData("vedika_agarwal")} dataKey="VedikaAgarwal" />
                    <TeamMember imgData={findImageData("sid_shah")} dataKey="SidShah" />
                    <TeamMember imgData={findImageData("kushaal_virmani")} dataKey="KushaalVirmani" />
                    <TeamMember imgData={findImageData("william_james_rao")} dataKey="WilliamJamesRao" />
                </TeamMembersContainer>
            </TeamMembersSection>
            <Section background="#fff" style={{marginTop: "70px"}}>
                <SectionWrapper>
                    <SubTitle fontSize="36px" mfontSize="24px" lineHeight="43px" mlineHeight="28.8px" style={{textAlign: "left"}} className={hindiBold}>
                        <SassyText>{t("OurCulture.Title")}</SassyText>
                    </SubTitle>
                    <CultureAndValuesContainer>
                        <CultureValueItem>
                            <CultureItemImgWrapper>
                                <StaticImage 
                                    src="../assets/images/accountable-to-customers.webp"
                                    alt="accountable to customers"
                                />
                            </CultureItemImgWrapper>
                            <Text fontWeight="bold" fontSize="20px" lineHeight="37px" mfontSize="14px" className={hindiBold}>
                                {t("OurCulture.BeAccountable")}
                            </Text>
                        </CultureValueItem>
                        <CultureValueItem>
                            <CultureItemImgWrapper>
                                <StaticImage 
                                    src="../assets/images/business-owner-mindset.webp"
                                    alt="business owner mindset"
                                />
                            </CultureItemImgWrapper>
                            <Text fontWeight="bold" fontSize="20px" lineHeight="37px" mfontSize="14px" className={hindiBold}>
                                {t("OurCulture.BusinessOwnerMindset")}
                            </Text>
                        </CultureValueItem>
                        <CultureValueItem>
                            <CultureItemImgWrapper>
                                <StaticImage 
                                    src="../assets/images/take-pride-in-work.webp"
                                    alt="take pride in work"
                                />
                            </CultureItemImgWrapper>
                            <Text fontWeight="bold" fontSize="20px" lineHeight="37px" mfontSize="14px" className={hindiBold}>
                                {t("OurCulture.PrideInWork")}
                            </Text>
                        </CultureValueItem>
                        <CultureValueItem>
                            <CultureItemImgWrapper>
                                <StaticImage 
                                    src="../assets/images/steward-investor-capital.webp"
                                    alt="steward investor capital"
                                />
                            </CultureItemImgWrapper>
                            <Text fontWeight="bold" fontSize="20px" lineHeight="37px" mfontSize="14px" className={hindiBold}>
                                {t("OurCulture.StewardOfCapital")}
                            </Text>
                        </CultureValueItem>
                        <CultureValueItem>
                            <CultureItemImgWrapper>
                                <StaticImage 
                                    src="../assets/images/ambassador-of-company.webp"
                                    alt="ambassador of company"
                                />
                            </CultureItemImgWrapper>
                            <Text fontWeight="bold" fontSize="20px" lineHeight="37px" mfontSize="14px" className={hindiBold}>
                                {t("OurCulture.AmbassadorOfCompany")}
                            </Text>
                        </CultureValueItem>
                    </CultureAndValuesContainer>
                </SectionWrapper>
            </Section>
            <Section background="rgba(255, 247, 241, 1)">
                <SectionWrapper>
                    <SubTitle fontSize="36px" mfontSize="36px" lineHeight="43px" mlineHeight="28.8px" style={{textAlign: "left", marginTop: "45px"}} className={hindiBold}>
                        <SassyText>{t('JoinUs.Title')}</SassyText>
                    </SubTitle>
                    <JoinUsSection>
                        <Text fontSize="20px" mfontSize="16px" fontWeight="700" lineHeight="36px" mlineHeight="26px" style={{textAlign: "left", maxWidth: "680px"}} className={hindiRegular}>
                            <Trans i18nKey="JoinUs.Desc">We’re always hiring exceptional talent. If you can’t find what you’re looking for, mail us at <a href="mailto:admin@verak.in" style={hyperlinkStyles}><strong>admin@verak.in</strong></a>.</Trans> 
                        </Text>
                        <TeamGraphicContainer>
                            <StaticImage 
                                src="../assets/images/team_graphic.webp"
                                alt="team"
                                width={334}
                                height={168}
                                loading="lazy"
                            />
                        </TeamGraphicContainer>
                    </JoinUsSection>
                </SectionWrapper>
            </Section>
            <Section>
                <SectionWrapper background="#fff">
                    {t('OpenRoles', {returnObjects: true})?.map(openRoleData => <OpenRoleCard openRoleData={openRoleData} />)}
                    <Text fontSize="16px" mfontSize="12px" lineHeight="21.6px" mlineHeight="21.6px" className={hindiRegular}>
                        <Trans i18nKey="OtherRolesInfo.Line1">Please note that we advertise role in Software Development, Product Design and Product Management via <a href="https://www.linkedin.com/company/verak" target="_blank" rel="noopener noreferrer" style={hyperlinkStyles}><strong>LinkedIn</strong></a> & <a href="https://twitter.com/VerakInsurance" target="_blank" rel="noopener noreferrer" style={hyperlinkStyles}><strong>Twitter</strong></a> exclusively. The below roles are related to our Sales and After-Sales team.</Trans>
                    </Text>
                    <Text fontSize="16px" mfontSize="12px" lineHeight="21.6px" mlineHeight="21.6px" className={hindiRegular}>
                        <Trans i18nKey="OtherRolesInfo.Line2">Candidates who are looking for Point Of Sale (POS) registration with us as per the IRDAI guidelines may send us an email to <a href="mailto:admin@verak.in" style={hyperlinkStyles}><strong>admin@verak.in</strong></a> with their basic qualifications.</Trans>
                    </Text>
                </SectionWrapper>
            </Section>
            <Section background="#FFF7F1" style={{marginTop: "24px"}}>
                <SectionWrapper>
                    <HelpingCustomersSection>
                        <HelpingCustomersImgWrapper className="on-desktop">
                            <StaticImage 
                                src="../assets/images/help.webp"
                                alt="helping customers"
                                width={486}
                                height={342}
                            />
                        </HelpingCustomersImgWrapper>
                        <div style={{width: "100%"}}>
                            <SubTitle fontWeight="bold" fontSize="36px" mfontSize="24px" lineHeight="62px" mlineHeight="42px" style={{textAlign: "left"}} className={hindiBold}>
                                {t('ClosingSection.Title')}
                            </SubTitle>
                            <HelpingCustomersImgWrapper className="on-mobile" style={{margin: "1.5rem auto"}}>
                                <StaticImage 
                                    src="../assets/images/help.webp"
                                    alt="helping customers"
                                    width={176}
                                    height={124}
                                />
                            </HelpingCustomersImgWrapper>
                            <Text fontSize="20px" mfontSize="14px" lineHeight="25px" mlineHeight="17.64px" style={{marginTop: "1rem"}} className={hindiRegular}>
                                {t('ClosingSection.Subtitle')}
                            </Text>
                            <Button wide primary label={t('CTALabels.GetAQuote')} onClick={handleGetQuoteClick} style={{margin: "auto", marginTop: "2rem"}} />
                        </div>
                    </HelpingCustomersSection>
                </SectionWrapper>
            </Section>
            <Footer />
        </>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["our-team", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    team_member_photos: allFile(
        filter: {
            relativeDirectory: {regex: "/team/"}
        }
    ) {
        edges {
            node {
                id
                base
                relativePath
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        height: 310
                        formats: AUTO
                        width: 290
                    )
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
  }
`;

const TeamGraphicContainer = styled.div`
    width: min(334px, 100%);
    min-width: 250px;
    @media screen and (max-width: 768px) {
        width: min(160px, 100%);
    }
`

const OurTeamSubHeader = styled.div`
    display: flex; 
    justify-content: space-between; 
    margin-top: 48px; 
    gap: 80px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin-bottom: 100px;
    }
`

const TeamMembersSection = styled(Section)`
    margin-top: 100px;
    background: #fff;
    @media screen and (max-width: 768px) {
        margin-top: 60px;
    }
`

const TeamMembersContainer = styled.div`
    margin: auto;
    margin-top: 2rem; 
    display: flex; 
    gap: 4.5rem; 
    justify-content: center; 
    flex-wrap: wrap;
    width: min(1280px, 100%);
    @media screen and (max-width: 768px) {
        padding: 0 1rem;
        gap: 2rem;
    }
`

const TeamMemberImgWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: min(240px, 100%);
    margin: auto;
    @media screen and (max-width: 768px) {
        width: min(128px, 100%);
        margin: auto;
    }
`

const CultureAndValuesContainer = styled(TeamMembersContainer)`
    gap: 120px;
    margin-top: 90px;
    @media screen and (max-width: 768px) {
        margin-top: 70px;
        gap: 40px;
    }
`

const CultureValueItem = styled.div`
    display: flex;
    align-items: center;
    gap: 22px;
    width: min(486px, 100%);
    text-align: left;
`

const CultureItemImgWrapper = styled.div`
    width: min(236px, 100%);
    @media screen and (max-width: 768px) {
        width: 95px;
    }
`

const JoinUsSection = styled(OurTeamSubHeader)`
    margin-top: 24px;
    @media screen and (max-width: 768px) {
        margin-top: 45px;
        margin-bottom: 24px;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`

const OpenRoleCardContainer = styled.div`
    padding: 24px;
    border: 1px solid #B3B3B3;
    border-radius: 20px;
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
        padding: 20px 16px; 
    }
`

const OpenRoleDataContainer = styled.div`
    text-align: left;
    margin-top: 24px;
`

const OpenRoleData = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 0 2rem;
    @media screen and (max-width: 768px) {
        grid-template-columns: 110px 1fr;
        gap: 0 0.625rem;
    }
`

const OpenRoleDataType = styled(Text)`
    font-size: 16px; 
    line-height: 21.65px; 
    color: rgba(153, 153, 153, 1);
    @media screen and (max-width: 768px) {
        font-size: 14px; 
        line-height: 18.94px;
    }
`

const OpenRoleDataValue = styled(OpenRoleDataType)`
    color: #4C4C4C;
`

const OpenRoleApplyBtn = styled(Button)`
    margin-top: 28px;
    height: 52px; 
    background: rgba(255, 147, 79, 0.1); 
    border: 1px solid #FF934F; 
    color: #FF934F;  
    font-size: 18px;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        margin: auto;
        margin-top: 24px; 
        font-size: 14px;
    }
`

const HelpingCustomersSection = styled(OurTeamSubHeader)`
    gap: 46px;
    margin-top: 75px;
    @media screen and (max-width: 768px) {
        margin-top: 60px;
    }
`

const HelpingCustomersImgWrapper = styled.div`
    width: min(480px, 100%);
    @media screen and (max-width: 768px) {
        width: min(176px, 100%);
    }
`

export default OurTeam